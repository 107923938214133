<template>
  <v-text-field
    :disabled="disabled"
    dense
    label="Teléfono"
    v-model="leadPhone"
    :hint="hintText"
    persistent-hint
    :rules="phoneRules"
    @input="setPhone()"
  >
    <template v-slot:append>
      <icon-states :status="status" />
    </template>

    <template v-slot:prepend-inner>
      <div class="mx-1" style="padding-top:6px">
        {{ countrySelected.dialCode }}
      </div>
    </template>
    <template v-slot:prepend>
      <v-menu style="top: -12px" max-height="400px" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div v-bind="attrs" v-on="on" :disabled="disabled">
            <v-row style="padding-top:5px">
              <country-flag
                class="mx-1"
                :country="countrySelected.alpha2"
                size="normal"
              />
            </v-row>
          </div>
        </template>

        <v-card>
          <v-text-field
            v-model="search"
            label="Buscar"
            prepend-icon="mdi-magnify"
            @click.stop
          >
          </v-text-field>
          <v-list>
            <v-list-item
              v-for="(item, i) in filteredItems"
              :key="i"
              @click="onSelect(item)"
            >
              <v-list-item-avatar>
                <country-flag
                  class="mb-1"
                  :country="item.alpha2"
                  size="normal"
                />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{
                  item.nameLatinAmerica
                }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ item.dialCode }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>
<script>
import countries from "../../../helpers/countries";

export default {
  name: "CountryPhoneEntry",
  components: {
    CountryFlag: () => import("vue-country-flag"),
    IconStates: () => import("@/components/lead/components/IconStates.vue")
  },
  created() {
    this.init();
  },
  watch: {
    phone() {
      this.init();
    }
  },
  methods: {
    /**
     * Formatea el phone de acuerdo al prop "formatting"
     * @param {value} string es el numero de telefono sin formato
     * @returns null
     */
    formatPhone(value) {
      this.leadPhone = this.formatting ? value.replace(/ /g, "") : value;
    },
    onSelect(item) {
      this.countrySelected = item;
      this.setCodePhone();
    },
    setPhone() {
      this.formatPhone(this.leadPhone);
      this.$emit("setPhone", this.leadPhone);
      this.$emit("setCodePhone", this.countrySelected.dialCode);
    },
    setCodePhone() {
      this.$emit("setCodePhone", this.countrySelected.dialCode);
    },
    init() {
      this.formatPhone(this.phone);
      countries.forEach(e => {
        if (e.dialCode === this.codePhone) {
          this.countrySelected = e;
        }
      });
    }
  },
  computed: {
    filteredItems() {
      return countries.filter(item =>
        item.nameLatinAmerica.toLowerCase().includes(this.search.toLowerCase())
      );
    }
  },
  props: {
    phone: {
      String: String,
      default: ""
    },
    codePhone: {
      String: String,
      default: "+52"
    },
    hintText: {
      String: String,
      default: "Campo obligatorio en caso de no ingresar un correo *"
    },
    disabled: {
      Boolean: Boolean,
      default: false
    },
    status: {
      Number: Number,
      default: null
    },
    // Sirve para saber si le quita los espacios o no
    // puede servir para en caso de uso donde se implemente
    formatting: {
      type: Boolean,
      default: true
    }
  },
  data: () => ({
    leadPhone: "",
    phoneRules: [
      v => !!v || "Número de teléfono requerido",
      // v => v.length >= 10 || 'The phone number is incorrect',
      // v => v.length <= 10 || 'The phone number is incorrect',
      v => /^[0-9\s]+$/.test(v) || "Por favor, ingresa solo numeros"
    ],
    search: "",
    countrySelected: {
      alpha2: "MX",
      dialCode: "+52",
      nameLatinAmerica: "México"
    }
  })
};
</script>
